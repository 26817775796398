import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function IndexPage({ data }) {
  return (
    <Layout>
      <SEO title="About" />
      <div className="lg:m-auto lg:w-4/5 lg:mt-6">
        <div className="lead text-teal-300">
          <h2 className="text-4xl text-center">Unite Today!</h2>
          <div className="lg:flex text-justify">
            <div className="lg:w-1/2 lg:px-4">
              <p>
                Space Station 13, the greatest clown simulator ever created, or
                atmos simulator? Who knows, but if you have ever played SS13
                before you can agree on one thing and that is that BYOND sucks!
                This is where unitystation comes in. Started in November 2016
                unitystation has endeavored to ensure SS13 has a long and
                prosperous future outside of Byond by cloning the /TG/ source to
                Unity.
              </p>
              <p>
                It has a fully working net framework, a dedicated server, map
                editor, inventory, basic interactions, all the items and clothes
                from the TG branch, weapons, and damage. The project has a
                growing and dedicated community of contributors who are eager to
                see this thing become a reality. Best of all it is all open
                source and{" "}
                <a href="https://github.com/unitystation/unitystation">
                  available on GitHub
                </a>
                .
              </p>
            </div>
            <div className="lg:w-1/2 lg:px-4">
              <p>
                But the journey to Version 1.0 is still a long one and this is
                where patreon comes in. Funds raised will be used to place
                'Bounties' on each TODO ticket that will provide an incentive
                for contributors to complete work a lot sooner then what would
                of occurred. We call this the <b>Nanotrasen Bounty System</b>.
                Code submitted for each ticket will be peer reviewed before the
                bounty is awarded. On top of this patrons will have a say in the
                development of unitystation with larger donors having more
                power. Having the NBS system means unitystation can achieve
                version 1.0 at lightning speed while also rewarding those who
                take the time out to contribute!
              </p>
              <p>
                All transactions will be made visible and public on our{" "}
                <a href="https://discord.gg/tFcTpBp">discord channel</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
